import React from "react"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import Input from "../../components/forms/Input"
import TextArea from "../../components/forms/TextArea"
import Button from "../../components/Button"
import SectionTitle from "../../components/SectionTitle"

const CareersDetailes = () => {
  return (
    <>
      <Section>
        <Container>
          {/* wrap */}
          <div className="max-w-[700px] mx-auto items-start justify-start flex flex-col gap-14">
            <article className="flex-shrink-0 w-full max-w-full prose">
              <h2>Role</h2>
              <p>
                Proactively fabricate one-to-one materials via effective
                e-business. Completely synergize scalable e-commerce rather than
                high standards in e-services. Assertively iterate resource
                maximizing products after leading-edge intellectual capital.
              </p>
              <p>
                Quickly aggregate B2B users and worldwide potentialities.
                Progressively plagiarize resource-leveling e-commerce through
                resource-leveling core competencies. Dramatically mesh low-risk
                high-yield alignments before transparent e-tailers.
              </p>
              <h2>Skills & Qualification</h2>
              <p>
                Quickly aggregate B2B users and worldwide potentialities.
                Progressively plagiarize resource-leveling e-commerce through
                resource-leveling core competencies. Dramatically mesh low-risk
                high-yield alignments before transparent e-tailers.
              </p>
              <p>
                Distinctively re-engineer revolutionary meta-services and
                premium architectures. Intrinsically incubate intuitive
                opportunities and real-time potentialities. Appropriately
                communicate one-to-one technology after plug-and-play networks.
              </p>
              <ul>
                <li>
                  Efficiently enable enabled sources and cost effective
                  products.
                </li>
                <li>
                  Completely synthesize principle-centered information after
                  ethical communities.
                </li>
                <li>
                  Efficiently innovate open-source infrastructures via
                  inexpensive materials.
                </li>
                <li>
                  Compellingly embrace empowered e-business after user friendly
                  intellectual capital.
                </li>
              </ul>
              <p>
                Interactively actualize front-end processes with effective
                convergence. Synergistically deliver performance based methods
                of empowerment whereas distributed expertise.
              </p>
              <h2>Typical Daily Duties:</h2>
              <p>
                Collaboratively administrate empowered markets via plug-and-play
                networks. Dynamically procrastinate B2C users after installed
                base benefits. Dramatically visualize customer directed
                convergence without revolutionary ROI.
              </p>
              <ol>
                <li>
                  Work closely with our content team to publish new posts.
                </li>

                <li>
                  Work closely with our outreach team on various SEO campaigns.
                </li>
                <li>
                  tay up to date with all SEO and content marketing trends.
                </li>
                <li>
                  Proactively envisioned multimedia based expertise and
                  cross-media growth.
                </li>
                <li>
                  Perform keyword research including discovery and expansion of
                  keyword opportunities.
                </li>
                <li>
                  Completely pursue scalable customer service through
                  sustainable potentialities.
                </li>
                <li>
                  Credibly reintermediate backend ideas for cross-platform
                  models.
                </li>
                <li>
                  Progressively maintain extensive infomediaries via extensible
                  niches.
                </li>
                <li>
                  Enthusiastically engage fully tested process improvements
                  before top-line platforms.
                </li>
                <li>
                  Globally revolutionize global sources through interoperable
                  services.
                </li>
              </ol>
              <h2>Requirements:</h2>
              <p>
                Credibly innovate granular internal or "organic" sources whereas
                high standards in web-readiness.
              </p>
              <ol>
                <li>
                  Quickly drive clicks-and-mortar catalysts for change before
                  vertical architectures.
                </li>
                <li>
                  Credibly reintermediate backend ideas for cross-platform
                  models. Continually reintermediate integrated through
                  technically sound intellectual capital.
                </li>
                <li>
                  Distinctively exploit optimal alignments for intuitive
                  bandwidth.
                </li>
              </ol>
            </article>
            <div
              className="block w-full max-w-[350px] laptop:max-w-[550px]"
              id="ApplyForm"
            >
              <SectionTitle>Apply Now</SectionTitle>
              {/* from-and-button */}
              <form className="flex flex-col gap-8 mt-6">
                {/* from */}
                <div className="flex flex-col gap-6 ">
                  <Input
                    label="Full name"
                    placeholder="John Doe"
                    name="full-name"
                  />

                  <Input
                    type="email"
                    label="Email"
                    placeholder="johndoe@example.com"
                    name="email"
                  />
                  <TextArea label="Cover letter" name="cover-letter" />
                  <Input
                    type="checkbox"
                    id="Checkbox4"
                    name="checkbox"
                    label={
                      <>
                        I agree to the{" "}
                        <Link to="#">
                          <span className="font-medium text-body-md text-primary-600">
                            privacy policy.
                          </span>{" "}
                        </Link>
                      </>
                    }
                  />
                </div>
                <Button type="submit" size={"xl"}>
                  Apply
                </Button>
              </form>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default CareersDetailes
