import React from "react"
import {
  Dribbble,
  Facebook,
  Github,
  Linkedin,
  Twitter,
} from "react-bootstrap-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import TextSmall from "../../components/TextSmall"

const TeamCard1 = ({
  name = "John Doe",
  role = "UI Designer",
  socialUrl = { fb: "", gt: "", tw: "", ld: "", dr: "" },
  className = "",
  imageData,
}) => {
  const image = getImage(imageData)

  return (
    <>
      <div
        data-aos="fade-up"
        className={`relative overflow-hidden shadow-lg rounded-2xl group ${className}`}
      >
        <GatsbyImage
          image={image}
          alt={name}
          className="w-full transition-all duration-300 ease-out group-hover:scale-105 rounded-2xl"
        />

        {/* person-info */}
        <div className="absolute bottom-0 left-0 right-0 flex flex-col w-full gap-4 px-6 py-5 transition-all duration-300 ease-out translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 bg-neutral-25">
          <div className="flex flex-col gap-1">
            <p className="font-medium text-body-lg text-neutral-700">{name}</p>
            <TextSmall>{role}</TextSmall>
          </div>
          {/* social-icons */}
          <div className="flex gap-5">
            {socialUrl.ld && (
              <a href={socialUrl.ld} target="_blank" rel="noreferrer">
                <Linkedin className="font-medium text-neutral-600 text-body-xl" />
              </a>
            )}
            {socialUrl.dr && (
              <a href={socialUrl.dr} target="_blank" rel="noreferrer">
                <Dribbble className="font-medium text-neutral-600 text-body-xl" />
              </a>
            )}
            {socialUrl.tw && (
              <a href={socialUrl.tw} target="_blank" rel="noreferrer">
                <Twitter className="font-medium text-neutral-600 text-body-xl" />
              </a>
            )}
            {socialUrl.fb && (
              <a href={socialUrl.fb} target="_blank" rel="noreferrer">
                <Facebook className="font-medium text-neutral-600 text-body-xl" />
              </a>
            )}

            {socialUrl.gt && (
              <a href={socialUrl.gt} target="_blank" rel="noreferrer">
                <Github className="font-medium text-neutral-600 text-body-xl" />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default TeamCard1
