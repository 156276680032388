import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import ButtonOutline from "../../components/ButtonOutline"
import SectionText from "../../components/SectionText"
import TeamCard1 from "../../components/cards/TeamCard1"
import Button from "../../components/Button"

const Team1 = ({ className }) => {
  const { image1, image2, image3, image4, image5, image6, image7, image8 } =
    useStaticQuery(graphql`
      query {
        image1: allFile(filter: { relativePath: { eq: "team-member-1.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image2: allFile(filter: { relativePath: { eq: "team-member-2.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image3: allFile(filter: { relativePath: { eq: "team-member-3.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image4: allFile(filter: { relativePath: { eq: "team-member-4.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image5: allFile(filter: { relativePath: { eq: "team-member-5.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image6: allFile(filter: { relativePath: { eq: "team-member-6.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image7: allFile(filter: { relativePath: { eq: "team-member-7.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image8: allFile(filter: { relativePath: { eq: "team-member-8.png" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    `)

  return (
    <>
      <Section className={`bg-neutral-50 ${className}`}>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-14 tablet:gap-[82px] ">
            {/* top-part */}
            <div
              data-aos="fade-in"
              className="flex flex-col items-center justify-center"
            >
              <SectionTitle>
                {" "}
                Our awesome <span className="text text-primary-600">team</span>
              </SectionTitle>
              <SectionText className="tablet:w-[580px] text-center">
                Our philosophy is simple — hire a team of diverse, passionate
                people and foster a culture that empowers you to do you best
                work.
              </SectionText>
              <div className="flex gap-4">
                <Link to="/about-1">
                  <ButtonOutline size={"xl"} className="">
                    About us
                  </ButtonOutline>
                </Link>
                <Link to="/careers">
                  <Button size={"xl"}>Open positions</Button>
                </Link>
              </div>
            </div>
            {/* bottom-part */}
            <div className="">
              {/* team-cards */}
              <div className="grid max-w-[280px] mx-auto tablet:max-w-full grid-cols-1 gap-6 tablet:gap-10 tablet:grid-cols-2 laptop:grid-cols-4">
                {/* card */}
                <TeamCard1
                  name="Corey R. Berg"
                  role="Founder & CEO"
                  imageData={image1.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    dr: "https://dribbble.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="Honkong Bee"
                  role=" Product Designer"
                  imageData={image2.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    gt: "https://github.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="Julia J. Hill"
                  role="Product Manager"
                  imageData={image3.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    dr: "https://dribbble.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="John M. Bates"
                  role="Backend Developer "
                  imageData={image4.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    gt: "https://github.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="Winnie J. Homne"
                  role="UX Researcher"
                  imageData={image5.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    dr: "https://dribbble.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="Julie J. Nesbit"
                  role="Backend Developer"
                  imageData={image6.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    gt: "https://github.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="Alden R. Gray"
                  role="Product Designer"
                  imageData={image7.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    dr: "https://dribbble.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
                <TeamCard1
                  name="Jason R. Moore"
                  role="Product Manager"
                  imageData={image8.nodes[0]}
                  socialUrl={{
                    tw: "https://twitter.com/",
                    gt: "https://github.com/",
                    ld: "https://www.linkedin.com/",
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Team1
