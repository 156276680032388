import React from "react"

const TextArea = ({
  placeholder = "Your Message...",
  className = "",
  inputClassName = "",
  labelClassName = "",
  label = "Message",
  required = true,
  rows = "3",
  ...rest
}) => {
  return (
    <>
      <label className={`block w-full ${className}`}>
        {label && (
          <span
            className={`text-neutral-700 mb-1 inline-block font-medium ${labelClassName}`}
          >
            {label}
          </span>
        )}

        <textarea
          className={`block w-full mt-1 rounded-lg shadow-xs border-neutral-300 focus:border-primary-300 outline-none duration-200 focus:ring focus:ring-neutral-300 focus:ring-opacity-50 ${inputClassName}`}
          rows={rows}
          placeholder={placeholder}
          required
          {...rest}
        ></textarea>
      </label>
    </>
  )
}
export default TextArea
