import React from "react"
import { Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Button from "../components/Button"
import CareersDetails from "../sections/careers/CareersDetails"
import Team1 from "../sections/teams/Team1"

const CareerDetailsPage = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25 ",
        }}
      >
        <GatsbySeo
          title={`Kloft - Back-end developer`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="Back-end developer"
          preTitle="Devlopment"
          text="Dynamically innovate resource-leveling customer service for state of the art customer service."
          className=""
        >
          <Link to="#ApplyForm" className="mt-8">
            <Button size={"2xl"}>Apply now</Button>
          </Link>
        </InnerHero>
        <CareersDetails />
        <Team1 />
      </PageWrapper>
    </>
  )
}

export default CareerDetailsPage
